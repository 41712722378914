import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Tilt from 'react-parallax-tilt'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import tachyons from 'tachyons-components'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Features from '../components/Features'
import GlobalStyle from '../styles/GlobalStyle'

const PageContainer = tachyons('div')`
experience pv4-ns
`

const ArticleContainer = tachyons('article')`
dt wrap pt5 pt6-ns pb5
`

const ArticleContainerHeadings = tachyons('div')`
fl w-100 w-30-l tl
`

const ArticleContainerDescriptions = tachyons('div')`
w-100 w-70-l ph0-l fl lh-copy
`

const SectionDivider = tachyons('section')`
w-100 dt
`

const Divider = tachyons('div')`
wrap hr-top
`

const SectionHeader = tachyons('h2')`
f3 fw6 mb2 mt0
`

const SectionContainer = tachyons('section')`
w-100 mt5
`

const MainContentHeader = tachyons('h1')`
fw6 f3 f2-ns mt4 mb3
`

const ContainerMainContent = tachyons('div')`
dt-ns dt--fixed-ns wrap ph0-ns
`

const ContainerIntro = tachyons('div')`
dt wrap pt5 pb0 hr-top
`

const ContainerIntroHeading = tachyons('div')`
fl w-100 w-30-l mb0
`

const ContainerIntroDescription = tachyons('div')`
w-100 w-70-l fl lh-copy
`

const IntroHeading = tachyons('h2')`
mb2 mt0 f3 fw6
`

const IntroDescription = tachyons('p')`
mt0 pb0
`

export const ExperiencePageTemplate = ({
  content,
  contentComponent,
  mainHeader,
}) => {
const PageContent = contentComponent || Content

return (
  <div>
    <GlobalStyle />
    <MainContentHeader>{mainHeader}</MainContentHeader>
    <PageContent className="content lh-copy" content={content} />
  </div>
);
}

ExperiencePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ExperiencePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { markdownRemark: post } = data
  const title= frontmatter.title
  const description= frontmatter.description
  const hero=frontmatter.main.hero
  const introHeader= frontmatter.intro.heading
  const introDescription= frontmatter.intro.description
  const introBlurbs= frontmatter.intro.blurbs

  return (
    <Layout>
      <Helmet titleTemplate="%s · Ajmal Afif">
        <title>{frontmatter.title}</title>
        <meta name="description" content={frontmatter.description} />
        <meta
          name="twitter:title"
          content={`${frontmatter.title} · Ajmal Afif`}
        />
        <meta name="twitter:description" content={frontmatter.description} />
        <meta
          property="og:title"
          content={`${frontmatter.title} · Ajmal Afif`}
        />
        <meta property="og:description" content={frontmatter.description} />
      </Helmet>
      <PageContainer>
        <ArticleContainer>
          <ArticleContainerHeadings>
            <SectionHeader>{title}</SectionHeader>
          </ArticleContainerHeadings>
          <ArticleContainerDescriptions>
            {description}
          </ArticleContainerDescriptions>
        </ArticleContainer>

        <SectionDivider>
          <Divider />
        </SectionDivider>

        <SectionContainer>
          <ContainerMainContent>
            <Tilt
              tiltReverse="true"
              tiltMaxAngleX="2"
              tiltMaxAngleY="4"
              glareMaxOpacity="0.7"
              glareReverse="true"
              glareEnable="true"
              gyroscope="true"
            >
              <PreviewCompatibleImage imageInfo={hero} />
            </Tilt>
            <ExperiencePageTemplate
              mainHeader={frontmatter.main.heading}
              content={post.html}
              contentComponent={HTMLContent}
            />
          </ContainerMainContent>
        </SectionContainer>

        <SectionContainer>
          <ContainerIntro>
            <ContainerIntroHeading>
              <IntroHeading>{introHeader}</IntroHeading>
            </ContainerIntroHeading>
            <ContainerIntroDescription>
              <IntroDescription>{introDescription}</IntroDescription>
            </ContainerIntroDescription>
          </ContainerIntro>
        </SectionContainer>
        <Features gridItems={introBlurbs} />
      </PageContainer>
    </Layout>
  );
}

ExperiencePage.propTypes = {
  helmet: PropTypes.object,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const ExperiencePageQuery = graphql`
  query ExperiencePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        description
        main {
          heading
          hero {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1080, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
        intro {
          blurbs {
            heading
            text
            alt
            image {
              childImageSharp {
                fixed(width: 200, height: 200, quality: 90) {
                  ...GatsbyImageSharpFixed_tracedSVG
                }
              }
            }
          }
          heading
          description
        }
      }
    }
  }
`

export default ExperiencePage