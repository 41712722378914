import React from 'react'
import PropTypes from 'prop-types'
import Tilt from 'react-parallax-tilt'
import PreviewFixedImage from '../components/PreviewFixedImage'
import tachyons from 'tachyons-components'

const SectionFeatures = tachyons('div')`
w-100 mt0
`
const ContainerFeature = tachyons('div')`
wrap pt4 pt5-l cf
`
const ContainerFeatureImage = tachyons('div')`
fr w-100 w-40-l tc
`
const ContainerFeatureContent = tachyons('div')`
fl w-100 w-60-l
`
const FeatureTitle = tachyons('h3')`
mt1 mb2 mt4-l pt3-l fw6
`
const FeatureDescription = tachyons('p')`
mt0 lh-copy
`

const Features = ({ gridItems }) => (
  <SectionFeatures>
    {gridItems.map(item => (
      <ContainerFeature key={item.text}>
        <ContainerFeatureImage>
          <Tilt
            tiltReverse="true"
            tiltMaxAngleX="25"
            tiltMaxAngleY="25"
            gyroscope="true"
          >
            <PreviewFixedImage imageInfo={item} />
          </Tilt>
        </ContainerFeatureImage>
        <ContainerFeatureContent>
          <FeatureTitle>{item.heading}</FeatureTitle>
          <FeatureDescription>{item.text}</FeatureDescription>
        </ContainerFeatureContent>
      </ContainerFeature>
    ))}
  </SectionFeatures>
);

Features.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default Features
